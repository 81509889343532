<template>
  <div class="d-flex flex-column flex-grow-1 flex-fill mt-3">
    <div
      class="d-flex flex-column flex-grow-1 grey lighten-4 "
      style="border-radius: 3px;"
      v-if="cashPurchases.length > 0"
    >
      <span class=" ft font-size-md ml-2 mt-2 text-uppercase font-weight-medium"
        >All Cash Purchases</span
      >
      <div
        v-for="(purchase, index) in cashPurchases"
        :key="index"
        class="d-flex flex-column flex-grow-1 my-1 white pa-2 ma-2 box-shadow-light"
      >
        <div class="d-flex flex-row flex-grow-1 pl-3 pr-3 pt-5 pb-1">
          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            rules="required"
            class="d-flex flex-grow-1"
          >
            <v-autocomplete
              dense
              :items="suppliers"
              item-value="id"
              autofocus
              outlined
              return-object
              item-text="name"
              prepend-icon="store"
              class="ft font-weight-medium font-size-md"
              label="Choose a supplier"
              v-model="purchase.supplierId"
              :error-messages="errors[0]"
            >
              <template v-slot:no-data>
                <v-list>
                  <v-list-item dense>
                    <span class="font font-weight-medium font-size-md"
                      >No suppliers' have been added yet.
                    </span>
                  </v-list-item>
                </v-list>
              </template>
              <template v-slot:item="{ item }">
                <span class="ft font-weight-medium font-size-md">{{
                  item.name
                }}</span>
              </template>
            </v-autocomplete>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            rules="required|decimals"
            class="d-flex flex-grow-1"
          >
            <v-text-field
              outlined
              label="Total Balance/Amount"
              prepend-icon="credit_card"
              :prefix="
                currency && currency.length > 0 ? currency[0].symbol : 'GHS'
              "
              class="ft font-weight-medium font-size-md"
              dense
              v-model="purchase.amount"
              :error-messages="errors[0]"
            />
          </ValidationProvider>

          <ValidationProvider tag="div" class="d-flex flex-column flex-grow-1">
            <v-text-field
              outlined
              dense
              v-model="purchase.invoiceNumber"
              label="Invoice or Payment No."
              prepend-icon="receipt_long"
              hint="Invoice or payment number."
              class=" font font-weight-medium font-size-md"
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            tag="div"
            v-if="salesPaymentOptions.length > 0"
            rules="required"
            class="d-flex flex-grow-1"
          >
            <v-autocomplete
              dense
              outlined
              :items="salesPaymentOptions"
              item-value="id"
              v-model="purchase.isPaymentMadeFromSalesAmount"
              item-text="value"
              prepend-icon="store"
              class="ft font-weight-medium font-size-md"
              label="Choose Source Of payment"
              :error-messages="errors[0]"
            >
              <template v-slot:no-data>
                <v-list>
                  <v-list-item dense>
                    <span class="font font-weight-medium font-size-md"
                      >No suppliers' have been added yet.
                    </span>
                  </v-list-item>
                </v-list>
              </template>
              <template v-slot:item="{ item }">
                <span class="ft font-weight-medium font-size-md">{{
                  item.value
                }}</span>
              </template>
            </v-autocomplete>
          </ValidationProvider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="red"
                class="mt-1"
                @click.stop="removeCashPurchase(index)"
                small
              >
                <i class="material-icons-outlined">delete</i>
              </v-btn>
            </template>
            <span class="ft font-weight-medium font-size-sm">Remove Item</span>
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex flex-row justify-end my-2 mr-2 mb-2 flex-grow-1">
        <v-btn
          @click="addCashPurchase"
          :disabled="
            totalSalesAmount <= totalAmountTallied && hasMDsAllocationTallied
          "
          small
          color="primary"
        >
          <span
            class="ft font-weight-medium font-size-sm"
            style="font-size: 12px !important;"
          >
            Add Cash Purchase
          </span>
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column flex-grow-1 my-1 justify-center grey lighten-4 pa-5"
      style="align-items:center;"
    >
      <img src="@/assets/pallets.svg" style="width: 80px;" />
      <span class="ft font-weight-medium"
        >Record all cash purchases made on
        <kbd class="ml-1">{{ date }}</kbd> shop activities or operations</span
      >
      <span class="ft font-weight-medium font-size-sm"
        >Cash purchases are goods acquired from a supplier and paid with
        cash</span
      >
      <v-btn @click="addCashPurchase" color="primary" class="mt-2">
        <span
          class="ft font-weight-medium font-size-sm"
          style="font-size: 12px !important;"
          >Add Cash Purchase</span
        >
      </v-btn>
    </div>
  </div>
</template>
<script>
import { extend, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { decimals } from "../../utils/resolvers";
extend("required", {
  ...required,
  message: "Input field is required"
});
extend(decimals, decimals);
export default {
  name: "CashPurchases",
  props: {
    date: String,
    currency: {
      type: Array,
      default: () => []
    },
    cashPurchases: {
      type: Array,
      default: () => []
    },
    suppliers: {
      type: Array,
      default: () => []
    },
    salesPaymentOptions: {
      type: Array,
      default: () => []
    },
    totalSalesAmount: {
      type: Number,
      default: 0
    },
    totalAmountTallied: {
      type: Number,
      default: 0
    },
    hasMDsAllocationTallied: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider
  },
  data: () => ({}),
  methods: {
    addCashPurchase() {
      this.$emit("addCashPurchase", {
        supplierId: "",
        amount: "",
        name: "",
        slug: "",
        invoiceNumber: "",
        isPaymentMadeFromSalesAmount: "",
        type: "cash-purchase"
      });
    },
    removeCashPurchase(index) {
      this.cashPurchases.splice(index, 1);
    }
  }
};
</script>
